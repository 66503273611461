import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/new-years-evil-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 slasher movie, New Year's Evil"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 31</h1>
                    <h2>New Year's Evil</h2>
                    <h3>January 8, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Emmet Alston // <b>Starring:</b> Roz Kelly &amp; Kip Niven</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/31-New-Years-Evil-e2e50u1/a-aaqnrmp" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 31, New Year's Evil"></iframe>
                        </div>
                        <p><Link to="/transcripts/new-years-evil">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave kick off the new year and hit peak holiday horror movie burnout when they go deep with the 1980 slasher movie, New Year's Evil. Cannon Group wasn't exactly known for horror movies and it'll take them a few years before they work it out but these nascent attempts to get a piece of the action during the golden age of the slasher turned out some really weird misfires and New Year's Evil is leading the charge. More like a giallo than anything else, it seems to possess a profound misunderstanding of what audiences were expecting from a slasher movie and delivers some seriously silly murder setpieces.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/fright-night">Next Episode</Link></li>
                        <li><Link to="/episodes/black-christmas">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)